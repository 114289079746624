import Q from 'q';
import _ from 'underscore';
import moment from 'moment';
import {extend} from "vue-router/src/util/misc";
const detail = require(`@/lib/data-service/flight/flight_domestic_buyer_manage_order_detail`);
import internationalDetail from "@/lib/data-service/flight/flight_international_buyer_manage_order_detail";
import queryAccountDetail from "@/lib/data-service/flight/wallet_account_queryAccountDetail";
import findChangeOrder from "@/lib/data-service/flight/fligthInternationalAdmin/findChangeOrderDetail";
import PayMenthod from "@/lib/common-service/getPayModule";
import PaymentMethodSelector from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/payment-method-selector/1.3.2/payment-method-selector-001/index.vue';
import payCheck from "@/lib/data-service/flight/payment_payCheck";

import payment from "@/lib/data-service/flight/newFligthApi/payment_params";

export default {
    data() {
        return {
            // defer_detail_completed:Q.defer(),
            relaxPayResponse: null,
            relaxPayDetailResponse: null,
            dialogVisible: false,
            orderInfoShow: false,
            payRadio: "",
            loading: true,
            // 订单号
            orderNo: null,
            // 订单信息
            detail: null,
            // 航班信息
            ordersData: null,
            // 乘客数量
            passengerVal: 1,
            btnShow: false,
            // 乘客类型
            psgTypeOption: [
                {
                    value: "1",
                    label: "成人",
                    disabled: false
                },
                {
                    value: "2",
                    label: "儿童",
                    disabled: false
                }
            ],
            // 联系人信息
            contact: {
                contactEmail: "",
                contactName: "",
                remark: "",
                contactPhone: ""
            },
            // 旅客证件
            cardType: [
                { value: "0", name: "身份证" },
                { value: "1", name: "护照" },
                // {value: 2, name: ''},
                // {value: 3, name: '身份证'},
                { value: "4", name: "港澳通行证" },
                { value: "5", name: "台胞证" },
                { value: "6", name: "台湾通行证" },
                { value: "7", name: "回乡证" },
                { value: "8", name: "军官证" },
                { value: "9", name: "其他" }
            ],
            // 是否同意胤之旅规则
            checked: false,
            // 乘客信息
            passenger: [
                {
                    // 出生日期，格式：yyyy-MM-dd
                    birthday: "",
                    // 证件号码
                    cardNo: "",
                    // 证件类型，0-身份证 1-护照 4-港澳通行证 5-台胞证 6-台湾通行证 7-回乡证 8-军官证 9-其他
                    cardType: 0,
                    // 是否保存至常旅客，0-不保存 1-保存
                    isSaveFrequent: 0,
                    // 手机号
                    mobile: "",
                    // 名字
                    psgName: "",
                    // 乘客类型，1-成人 2-儿童
                    psgType: "1",
                    sex: 0
                }
            ],
            // 乘客类型
            psgType: 3,
            // 输入框验证规则
            // 成人数量
            adultNub: 1,
            // 儿童数量
            childNub: 0,
            // 成人价格
            adultPrice: 0,
            // 儿童价格
            childPrice: 0,
            AllPrice: 0,
            PriceText: "",
            rate: 1,
            detailData: null,
            queryAccount: {},
            image_url_airline_blank_map: `https://lyn.oss-cn-shenzhen.aliyuncs.com/temp/airline-blank-map.jpg`,
            AlipayHtml: "",

            dialogErrorVisible: false,
            dialogError2Visible: false,
            returnSecond: 5,
            returnSetInterval: '',
            errorText: '',

            dialogEasyPayVisible: false,
            dialogEasyPayPasswordVisible: false,
            msg: '',
        };
    },
    components: { PayMenthod, PaymentMethodSelector },
    methods: {
        countAduPrice(detail) {
            let price = parseInt(detail.aduPrice) - parseInt(detail.aduSettlementPrice)
            if (price <= 0) {
                return 0
            } else {
                return price
            }
        },
        countChdPrice(detail) {
            let price = parseInt(detail.chiPrice) - parseInt(detail.chiSettlementPrice)
            if (price <= 0) {
                return 0
            } else {
                return price
            }
        },
        // 计算周几
        Getweek(index) {
            let time = this.$moment(index).valueOf();
            let str = [
                "星期一",
                "星期二",
                "星期三",
                "星期四",
                "星期五",
                "星期六",
                "星期日"
            ];
            let week = Number(this.$moment(time).format("E")) - 1;
            return str[week];
        },
        getOrderList() {
            let type = this.$route.query.type;
            type
                ? this.$router.push({ name: "air-ticket-admin-international-list" })
                : this.$router.push({ name: "air-ticket-admin-purchase-order-list" });
        },
        getPayMoney(val) {
            queryAccountDetail().then(res => {
                this.queryAccount = res;
                res.usableAmount > val && res.passwordStatus == 1
                    ? (this.payRadio = "YPAY")
                    : (this.btnShow = true);
            });
        },
        // 通过身份证获取生日
        fromIdCard(index) {
            let idCard = this.passenger[index].cardNo;
            let birthday = "";
            if (idCard != null && idCard != "") {
                if (idCard.length == 15) {
                    birthday = "19" + idCard.substr(6, 6);
                } else if (idCard.length == 18) {
                    birthday = idCard.substr(6, 8);
                }
                birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
            }
            this.passenger[index].birthday = birthday;
        },
        airDetail() {
            let type = this.$route.query.type;
            type
                ? this.$router.push({
                    name: "air-ticket-admin-international-list-detail",
                    query: { ordersNo: this.orderNo, type: "airDetails" }
                })
                : this.$router.push({
                    name: "air-ticket-admin-airDetails",
                    query: { ordersNo: this.orderNo }
                });
        },
        // 获取信息
        getAirParams() {
            this.loading = true;
            this.orderNo = this.$route.query.ordersNo;
            let type = this.$route.query.type;
            let params = {
                orderNo: this.orderNo
            };
            if (type) {
                internationalDetail({ orderId: this.orderNo }).then(
                    res => {
                        this.loading = false;
                        this.detailData = res.data;
                        this.passenger = res.data.passengerList;
                        this.contact = res.data;
                        // fn(res.data.payAmount);
                        if (this.$route.query.pay) {
                            this.getFillingDetail();
                        } else {
                            this.ordersData = res.data.passengerList[0].routeList;
                            this.AllPrice = res.data.payAmount ? res.data.payAmount : 0;
                        }
                    },
                    error => {
                        this.loading = false;
                    }
                );
            } else {
                return detail(params).then(
                    res => {
                        console.log("国内机票", res);
                        this.loading = false;
                        this.detail = res.orderDetail;
                        this.ordersData = res.orderDetail.segmentList;
                        this.relaxPayResponse = res.relaxPayResponse
                        this.relaxPayDetailResponse = res.relaxPayDetailResponse
                        // this.detail.fullReductionSnapshot
                        this.detail.fullReductionSnapshot
                            ? (this.detail.fullReductionSnapshot = JSON.parse(
                                this.detail.fullReductionSnapshot
                            ))
                            : "";
                        this.passenger = res.orderDetail.passengerList;
                        this.contact.contactEmail = res.orderDetail.contactEmail;
                        this.contact.contactName = res.orderDetail.contactName;
                        this.contact.remark = res.orderDetail.remark;
                        this.contact.contactPhone = res.orderDetail.contactTel;
                        // this.AllPrice = res.orderDetail.totalFillingAmount
                        //   ? res.orderDetail.totalFillingAmount
                        //   : res.orderDetail.totalAmount;
                        // fn(res.orderDetail.totalAmount);
                        // return res

                        //defer_detail_completed.resolve(res);
                    },
                    error => {
                        this.loading = false;
                    }
                );
            }
        },
        getFillingDetail() {
            let chaOrderId = this.$route.query.chaOrderId;
            findChangeOrder({ chaOrderId: chaOrderId }).then(res => {
                this.ordersData = res.data.passengers[0].newRouteList;
                this.AllPrice = res.data.realAddMoneyAmount;
            });
        },
        // 深克隆
        deepClone(obj) {
            let _obj = JSON.stringify(obj);
            let objClone = JSON.parse(_obj);
            return objClone;
        },
        // 计算成人数量
        compAdultNub(data) {
            return data.psgType === 1;
        },
        // 计算儿童数量
        compChildNub(data) {
            return data.psgType === 2;
        },
        goOrderList() {
            let type = this.$route.query.type;
            let path = type
                ? "air-ticket-admin-international-list"
                : "air-ticket-admin-purchase-order-list";
            this.$router.push({ name: "pay-success-message", query: { path: path } });
        },
        // 提交表单
        submit() {
            if (!this.payRadio)
                return this.$message({ type: "warning", message: "请选择支付方式" });
            let type = this.$route.query.type;
            let payType = this.$route.query.pay;
            let orderId = this.$route.query.chaOrderId;
            let _this = this;
            let params = {
                orderNo: orderId ? orderId : this.orderNo,
                paymentChannel: this.payRadio,
                paymentType: payType ? payType : type ? 21 : 11,
                totalFee: this.AllPrice,
                paymentPassword: ""
            };

            // if (this.payRadio === "YPAY") {
            //   this.$prompt("请输入密码", "提示", {
            //     confirmButtonText: "确定",
            //     cancelButtonText: "取消",
            //     inputType: "password"
            //   })
            //     .then(({ value }) => {
            //       if (!value) return;
            //       params.paymentPassword = value;
            //       payment();
            //     })
            //     .catch(() => {});
            // } else {
            //   payment();
            // }

            // function payment() {
            //   _this.loading = true;
            //   pay(params).then(
            //     res => {
            //       if (res.code === _this.SUCCESS_CODE) {
            //         _this.loading = false;
            //         if (_this.payRadio === "YPAY") {
            //           return _this.goOrderList();
            //         }
            //         let { href } = _this.$router.resolve({
            //           name: "air-pay-page",
            //           query: { data: res.htmlStr }
            //         });
            //         _this.payRadio === "YEEPAY"
            //           ? window.open(res.htmlStr)
            //           : _this.payRadio === "ALIPAY"
            //             ? window.open(href, "_blank")
            //             : "";
            //         _this.dialogVisible = true;
            //       }
            //     },
            //     error => {
            //       _this.loading = false;
            //     }
            //   );
            // }
        },

        payInit() {
            let orderInfo;
            let __this = this;
            orderInfo = payment({ type: 11 });
            orderInfo.then(res => {
                this.$refs.payComponent.init({
                    count_down: {
                        enable: false
                        // pay_remaining_seconds: false
                    },
                    get_params() {
                        /*return  defer_detail_completed.promise.then(function (data) {

                        });*/

                        const detail = __this.detail;
                        let segmentList = detail.segmentList;
                        if (!_.isArray(segmentList)) {
                            const msg = `get_params parameter error, code=001`;
                            console.error(msg);
                            console.trace();
                            return Q.reject({msg: msg});
                        }
                        if (segmentList.length <= 0) {
                            const msg = `get_params parameter error, code=002`;
                            console.error(msg);
                            console.trace();
                            return Q.reject({msg: msg});
                        }
                        const first_segment = segmentList[0];


                        let businessContent = res.businessContent
                        // 如果允许使用轻松付
                        if (__this.relaxPayResponse) {
                            businessContent.refundTime = __this.relaxPayResponse.finalPaymentTime + ':00'
                        }
                        const p = {
                            companyId: res.companyId,
                            clubId: res.clubId,
                            orderNo: __this.detail.orderNo,
                            isUseCredit: __this.detail.creditState || false,
                            businessContent: businessContent,
                            subject: first_segment.sCityName + "-" + first_segment.eCityName + " （出票）",
                            // orderAmount: 3000,//订单金额，用于计算可以用哪些支付方式支付
                            orderAmount: __this.detail.payAmount,//订单金额，用于计算可以用哪些支付方式支付
                            count_down: {
                                enable: false
                                // pay_remaining_seconds: false
                            },
                            clientType: 1,//客户端类型：1.b2b，2.b2c，3.差旅,默认为1
                        };
                        return Q.resolve(p);
                    },

                    get_qing_song_fu_data() {
                        const relaxPayResponse = __this.relaxPayResponse;
                        const d = {};
                        d.ableAmount = 0;
                        d.countAmount = 0;
                        d.depositMount = 0;
                        d.finalPaymentTime = ``;
                        d.needMount = 0;
                        d.relaxPay = -1;
                        d.scharge = 0;
                        d.usableAmount = 0;

                        if (!relaxPayResponse) {
                            console.log(d)
                            return Q.resolve(d);
                        }
                        extend(d, relaxPayResponse);

                        return Q.resolve(d);
                    },

                    before_pay_event_handler() {
                        let data = {
                            orderNo: __this.detail.orderNo,
                        };
                        /*要隐藏的*/
                        /*if (__this.detail.manualStatus == 0) {
                            return payCheck(data).then(res => {
                                return Promise.resolve('success')
                            }).catch(error => {
                                return Promise.reject(error)
                            })
                        }*/
                        /*要隐藏的-end*/
                        /*根据code返回不一样的*/
                        if (__this.detail.orderSource === 7) {
                            return Promise.resolve('success')
                        } else {
                            return payCheck(data).then(res => {
                                return Promise.resolve('success')
                            }, error => {
                                let type = __this.$route.query.type
                                let errorCode = error.data.code
                                let allErrorCode = ['000103', '000102', '000200']
                                if (allErrorCode.indexOf(errorCode) >= 0 && !type) {
                                    __this.dialogErrorVisible = true
                                    __this.errorText = error.data.msg  // 000103: 座位不足，请重新搜索航班 000102: 航班价格变动，请重新搜索航班 000200,订单支付已超时，请重新下单
                                    __this.returnSecond = 5
                                    __this.returnSetInterval = setInterval(() => {
                                        __this.returnSecond--
                                        if (__this.returnSecond <= 0) {
                                            __this.returnToSearch()
                                        }
                                    }, 1000)
                                } else if (errorCode === '000201' && !type) {
                                    __this.errorText = error.data.msg
                                    __this.dialogError2Visible = true
                                } else {
                                    __this.errorText = error.data.msg
                                    __this.dialogError2Visible = true
                                }
                                return Promise.reject(error)
                            }).catch(error => {
                                return Promise.reject(error)
                            })
                        }

                        /*根据code返回不一样的-end*/
                    },
                    //支付成功事件处理方法
                    pay_successful_event_handler() {
                        __this.$router.push({
                            name: "air-ticket-admin-purchase-order-list"
                        });
                        __this.$message({ type: "success", message: "支付成功！" });
                    },
                    //支付失败事件处理方法
                    pay_failed_event_handler() {
                        __this.$message({
                            type: "warning",
                            message: "支付失败，请重新支付！"
                        });
                    }
                });
            });
        },
        returnToSearch() {
            console.log(this.dialogErrorVisible)
            if (!this.dialogErrorVisible) {
                return
            }
            this.dialogErrorVisible = false
            clearInterval(this.returnSetInterval)
            this.returnSecond = 0
            let adult = 0
            let child = 0
            let psgType = 1
            this.detail.passengerList.forEach(value => {
                if (value.psgType === 1) {
                    adult++
                } else {
                    child++
                }
            })
            if (adult > 0 && child === 0) {
                psgType = 1
            } else if (adult === 0 && child > 0) {
                psgType = 2
            } else {
                psgType = 3
            }
            /*
            *  airType: this.airType, // 航程类型// 航程类型
        time1: this.time1, //  出发时间
        time2: this.time2, //  返回时间
        time2Show: this.time2Show, // 返回时间是否可用
        // timeRoll: this.timeRoll, //滚动条时间
        psgType: this.psgType, //  乘机人类型
        ClassType: this.ClassType, //舱位等级
        airlines: this.airlines //航空公司
        starCity: this.starCity,
          starCity1: this.starCity1,
          endCity: this.endCity,
          endCity1: this.endCity1*/
            let params = {
                airType: this.detail.flightType, // 航程类型// 航程类型
                time1: moment(this.ordersData[0].beginDate).valueOf(), //  出发时间
                time2: this.detail.flightType === 2 ? moment(this.ordersData[1].beginDate).valueOf() : null, //  返回时间
                time2Show: this.detail.flightType === 1 ? true : false, // 返回时间是否可用
                psgType: psgType, //  乘机人类型
                ClassType: 0, //舱位等级
                airlines: '', //航空公司
                starCity: this.ordersData[0].sAirportCode,
                starCity1: `${this.ordersData[0].sCityName}(${this.ordersData[0].sAirportCode})`,
                endCity: this.detail.flightType === 2 ? this.ordersData[1].sAirportCode : this.ordersData[0].eAirportCode,
                endCity1: this.detail.flightType === 2 ? `${this.ordersData[1].sCityName}(${this.ordersData[1].sAirportCode})` : `${this.ordersData[0].eCityName}(${this.ordersData[0].eAirportCode})`
            }
            console.log('params', params)
            this.$router.push({
                name: "air-list",
                // path: '/air-list',
                query: {
                    airdata: JSON.stringify(params)
                }
            });
        },
    },
    watch: {
        passenger: {
            handler: function () {
                this.adultNub = this.passenger.filter(this.compAdultNub).length;
                this.childNub = this.passenger.filter(this.compChildNub).length;
            },
            deep: true
        }
    },
    async created() {
        let res = await this.getAirParams();
        this.$nextTick(() => {
            this.payInit();
        });
    },
    mounted() {
    },
    activated () {
        clearInterval(this.returnSetInterval)
    },
    deactivated() {},
    destroyed () {
        clearInterval(this.returnSetInterval)
    },
};
